<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Fecha y Hora
          </h6>
          <b-form-input
            :value="formatFecha(gateway.fechaCreacion)"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Brigada
          </h6>
          <b-form-input
            :value="gateway.brigada.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="4"
      >
        <b-form-group>
          <h6 class="text-primary">
            Gateway
          </h6>
          <b-form-input
            :value="gateway.gateway.nombre"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Trabajo(s) Realizado(s)
          </h6>
          <b-form-input
            :value="getTrabajosRealizados()"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="gateway.supresor"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <h6 class="text-primary">
            Supresor
          </h6>
          <b-form-input
            :value="gateway.supresor"
            disabled
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="gateway.especificacion"
        cols="12"
        sm="12"
      >
        <b-form-group>
          <h6 class="text-primary">
            Especificación del Reemplazo de Dispositivos
          </h6>
          <b-form-textarea
            :value="gateway.especificacion"
            rows="2"
            disabled
            no-resize
          />
        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <vue-good-table
          :columns="columns"
          :rows="gateway.seguimiento"
          style-class="vgt-table condensed"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span v-if="props.column.field == 'detalle'">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-1
                variant="gradient-primary"
                @click="mostrarImagen(props.row)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <p v-if="props.row.comentarios">{{ props.row.comentarios }}</p>
            </span>
            <span v-else-if="props.column.field == 'fechaHora'">
              <span>{{ formatFecha(gateway.fechaCreacion) }}</span>
            </span>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        v-model="verDialogoDocumento"
        ok-only
        ok-variant="primary"
        ok-title="Aceptar"
        modal-class="modal-primary"
        centered
        size="lg"
        title="Detalle de la Imagen"
      >
        <b-card-text style="display: flex; justify-content: center">
          <b-img
            :src="urlImagen"
            fluid
            alt="Fluid-grow image')"
            style="max-height: 700px;"
          />
        </b-card-text>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BFormInput, BFormTextarea,
  BRow, BCol, BFormGroup, BButton, BModal, VBModal, BCardText, BImg,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { calcularFecha } from '@/utils/fechas'

export default {
  components: {
    VueGoodTable,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BModal,
    BImg,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    gateway: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      urlImagen: '',
      verDialogoDocumento: false,
      columns: [
        {
          label: 'Tipo',
          field: 'tipo',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaHora',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
        {
          label: 'Detalle',
          field: 'detalle',
          thClass: 'vgt-center-align',
          tdClass: 'text-center',
        },
      ],
      rows: [],
    }
  },
  methods: {
    getTrabajosRealizados() {
      return this.gateway.trabajosGateway.map(trabajo => trabajo.nombre).join(', ')
    },
    mostrarImagen(file) {
      this.urlImagen = ''
      this.urlImagen = file.url
      this.verDialogoDocumento = true
    },
    formatFecha(row) {
      return calcularFecha(row)
    },
  },
}
</script>

<style scoped>

</style>
